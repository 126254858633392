@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
   
  }
  #about,
  #services,
  #testimonials,
  #welcome,
  #blog,
  #portfolio,
  #contact,
  #contentwriting
  #footer
  #features
   {
    width: 100%;
  }


}
.collapsible .content {
  padding: 6px;
 
}
.collapsible .header {
 
  padding: 3px;
  cursor: pointer;
  margin:0 auto;
  width: 20px;
 
}

  /* for desktop */
  .whatsapp_float {
    position: fixed;
  
    bottom: 2px;
    left: 20px;
    /* background-color: #25d366; */
    color: #25d366;
    text-align: center;
    font-size: 30px;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 0px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        left: 10px;
        font-size: 22px;
    }
}
