.carousel {
    width: 100%;
    height: auto;
    
 
}
.overlay {
   background: rgba(0, 0, 0, 0.2)!important;
   
}

.carouselInner {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);

}

.carouselInner .left {
    flex: 5%;
    height: 100%;
  /*  background-color: rgb(0, 0, 0, 0.6);*/
    display: grid;
    place-items: center;
    color:white;
    cursor: pointer;
}



.carouselInner .center {
    flex: 30%;
    height: 40%;
    margin-top:10%;
    margin-left:0%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    
    
}
.carouselInner .center p {
   
   font-size: 30px;
   color:rgb(232, 230, 230);
}

.carouselInner .right {
    flex: 5%;
    height: 100%;
    /*background-color: rgb(0, 0, 0, 0.6);*/
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  
}


h1 .carousel {
    font-size: 45px;
    color:rgb(187, 187, 187);
}

p .carousel {
    font-size: 45px;
    color:#fff!important;
}
 h1, p .carousel {
   /*background-color: rgb(255, 255, 255, 0.8);*/
    padding: 0px;
    border-radius: 9px;
    color:rgb(250, 146, 90);
    font-size: 80px;
    margin-top:80px;
    font-family: "Raleway", sans-serif;
    font-weight: 700;


}


@media (max-width: 896px) {

    h1, p .carousel {
        /*background-color: rgb(255, 255, 255, 0.8);*/
         padding: 0px;
         border-radius: 9px;
         color:rgb(248, 163, 78);
         font-size: 40px;
     
         font-family: "Raleway", sans-serif;
        
         font-weight: 700;
        
     
     
     }
     p .carousel {
        font-size: 45px;
        color:#fff!important;
    }
}